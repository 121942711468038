<template>
  <div class="login">
    <div class="login-container">
      <header class="login__header">
        <h2
          class="tw-mt-[55px] tw-flex tw-justify-between tw-text-left tw-text-primary"
          :class="[{ 'tw-mb-[35px]': loginType === LoginType.PASSWORD }]"
        >
          <div>
            欢迎登录{{ schoolText }}
            <div
              v-if="!isYucai"
              class="minor-text tw-text-left tw-text-xs tw-font-normal"
            >
              数字化校园引领者
            </div>
          </div>

          <img
            v-if="loginType === LoginType.QR_CODE"
            class="tw-h-[54px] tw-cursor-pointer"
            :src="loginTypeMap[loginType].iconSrc"
            :alt="loginTypeMap[loginType].iconAlt"
            @click="onChangeLoginType"
          />

          <el-tooltip
            v-else
            :value="showTooltip"
            manual
            effect="dark"
            content="扫码登录 安全快捷"
            placement="right"
          >
            <img
              class="tw-h-[54px] tw-cursor-pointer"
              :src="loginTypeMap[loginType].iconSrc"
              :alt="loginTypeMap[loginType].iconAlt"
              @click="onChangeLoginType"
            />
          </el-tooltip>
        </h2>
      </header>

      <!-- 选择角色 -->
      <div v-if="!character" class="login-select">
        <p class="login-select__title">选择您的角色</p>
        <ul class="character">
          <li class="character-item" @click="changeCharacter('teacher')">
            <img
              class="character-item__img"
              src="@/assets/index/teacher.png"
              alt="teacher"
            />
            <p class="character-item__title">我是老师</p>
          </li>
          <li class="character-item" @click="changeCharacter('student')">
            <img
              class="character-item__img"
              src="@/assets/index/student.png"
              alt="student"
            />
            <p class="character-item__title">我是学生</p>
          </li>
        </ul>
      </div>
      <!-- 登录 -->
      <div v-else class="login-body">
        <QrcodeLoginSection
          v-if="isShowQrcodeLogin"
          ref="qrcodeLoginSectionRef"
          :isYucai="isYucai"
          @loginSuccess="onQrcodeLoginSuccess"
        />

        <el-form
          v-else
          ref="loginForm"
          label-position="top"
          label-width="80px"
          :model="form"
          :rules="rules"
        >
          <el-form-item label="账号" prop="loginName">
            <el-input
              id="loginName"
              v-model="form.loginName"
              :placeholder="loginNamePlaceholder"
              @keyup.enter.native="submit"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              id="password"
              v-model="form.password"
              placeholder="请输入密码"
              type="password"
              @keyup.enter.native="submit"
            ></el-input>
          </el-form-item>
          <div class="login-body__options">
            <el-button
              class="check-account"
              :class="{ ' tw-pointer-events-none tw-opacity-0': reLogin }"
              type="text"
              @click="checkAccount"
            >
              学生账号查询
            </el-button>
            <el-checkbox v-model="remember" class="remember-me">
              记住密码
            </el-checkbox>
          </div>
          <el-button
            class="login-body__btn"
            :loading="btnLoading"
            @click="submit"
          >
            登录
          </el-button>
        </el-form>
        <template v-if="!isYucai && !reLogin">
          <el-button class="btn__text--apply" type="text" @click="onChangeForm">
            申请学生体验账号
          </el-button>
          <div class="message-tip">教师账号请联系工作人员开通</div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import md5 from 'md5';

import iconPasswordLogin from '@/assets/login/icon-password-login.png';
import iconQrcodeLogin from '@/assets/login/icon-qrcode-login.png';
import { login, memberIdLogin } from '@/fetchs/aischool/SchoolLoginFacade';
import { LOGIN } from '@/stat-id/login';

import { LoginType } from '../enums';
import QrcodeLoginSection from './QrcodeLoginSection.vue';

export default {
  name: 'LoginForm',
  components: {
    QrcodeLoginSection,
  },
  props: ['memberIdLoginData', 'isYucai', 'isChangeBackToFront', 'reLogin'],
  data() {
    return {
      form: {
        loginName: '',
        password: '',
      },
      character: 'teacher',
      forcedEntry: 1,
      btnLoading: false,
      remember: false,
      localPassword: '',
      memberId: null,
      phone: null,
      rules: {
        loginName: [{ required: true, message: ' ', trigger: 'blur' }],
        password: [{ required: true, message: ' ', trigger: 'blur' }],
      },
      LoginType,
      loginType: LoginType.QR_CODE,
      loginTypeMap: {
        [LoginType.QR_CODE]: {
          iconSrc: iconPasswordLogin,
          iconAlt: '切换账号密码登录',
        },
        [LoginType.PASSWORD]: {
          iconSrc: iconQrcodeLogin,
          iconAlt: '切换扫码登录',
        },
      },
      showTooltip: true,
    };
  },

  computed: {
    schoolText() {
      return this.isYucai ? '育才.云朵朵' : '未来校';
    },

    loginNamePlaceholder() {
      return this.isYucai ? '请输入教师/学生' : '请输入教师/学生/教育局账号';
    },

    isShowQrcodeLogin() {
      return !this.isChangeBackToFront && this.loginType === LoginType.QR_CODE;
    },
  },
  watch: {
    memberIdLoginData(val) {
      if (val) {
        const { memberId, phone } = val;
        this.memberId = memberId;
        this.phone = phone;
        this.form.loginName = phone;
        this.form.password = '';
      }
    },

    isYucai: {
      handler(val) {
        this.loginType = val ? LoginType.QR_CODE : LoginType.PASSWORD;
      },
      immediate: true,
    },

    isChangeBackToFront: {
      handler(val) {
        if (val) {
          this.showTooltip = false;
        } else {
          // 切换翻转有个1s的动画时间，不设置该定时，tooltip的位置会有问题
          setTimeout(() => {
            this.showTooltip = true;
          }, 1000);
        }
      },
      immediate: true,
    },
  },

  created() {
    this.getLocal();
  },

  methods: {
    onChangeForm() {
      const changeType = 'APPLY';
      this.$emit('change-form', changeType);
      this.$refs.qrcodeLoginSectionRef?.clearTimer();
      window.KKLbigdata.send({
        type: 'click',
        click_id: LOGIN['BTN_APPLY_ACCOUNT'],
      });
    },

    onChangeLoginType() {
      this.loginType = Number(!this.loginType);
    },

    // 切换角色
    changeCharacter(type) {
      this.character = type;
    },
    // 提交表单验证
    submit() {
      this.$refs['loginForm'].validate((valid) => {
        if (!valid) return;

        const {
          form: { loginName },
          phone,
          btnLoading,
        } = this;

        if (btnLoading) return;

        window.KKLbigdata.send({
          type: 'click',
          click_id: LOGIN['LOGIN'],
        });

        if (loginName === phone) {
          this.loginWithMemberId();
          return;
        }
        this.memberId = null;
        this.phone = null;
        this.login();
      });
    },
    // 账号查询
    checkAccount() {
      this.$emit('check-account');
    },
    // 使用memberId登录
    async loginWithMemberId() {
      let {
        form: { password },
        btnLoading,
        localPassword,
        memberId,
      } = this;
      if (btnLoading) return;

      this.btnLoading = true;
      password = password === localPassword ? password : md5(password);
      const params = { memberId, password };
      try {
        const res = await memberIdLogin(params);
        this.setLocal();
        this.$emit('login-success', res);
      } catch (e) {
        KKL.message.toast(e.message || '登录失败');
        console.error(e);
      } finally {
        this.btnLoading = false;
      }
    },
    // 登录请求
    async login() {
      let {
        form: { loginName: mobileNo, password },
        btnLoading,
        localPassword,
      } = this;
      if (btnLoading) return;
      this.btnLoading = true;
      password = password === localPassword ? password : md5(password);
      const params = { mobileNo, password };
      try {
        const res = await login(params);
        this.setLocal();
        this.$emit('login-success', res);
      } catch (e) {
        KKL.message.toast(e.message || '登录失败');
        console.error(e);
      } finally {
        this.btnLoading = false;
      }
    },

    onQrcodeLoginSuccess(loginInfo) {
      this.$emit('login-success', loginInfo, LoginType.QR_CODE);
    },

    // 将密码存到本地
    setLocal() {
      const {
        remember,
        form: { loginName, password },
        localPassword,
        memberId,
        phone,
      } = this;
      if (!remember) {
        localStorage.removeItem('localUser');
        return;
      }
      const localUser = JSON.stringify({
        remember,
        loginName,
        memberId,
        phone,
        password: password === localPassword ? password : md5(password),
      });
      localStorage.setItem('localUser', localUser);
    },
    // 从本地获取密码
    getLocal() {
      const localUser =
        localStorage.getItem('localUser') &&
        JSON.parse(localStorage.getItem('localUser'));
      if (!localUser) return;
      const { remember, loginName, password, memberId, phone } = localUser;
      if (remember) {
        this.form = {
          loginName: loginName ? loginName : '',
          password: password ? password : '',
        };
        this.memberId = memberId;
        this.phone = phone;
        this.remember = remember;
        this.localPassword = password;
      }
    },
  },
};
</script>

<style lang="less" scoped>
/* 处理自动填充背景 */
/deep/ input:-webkit-autofill {
  box-shadow: inset 0 0 0 100px #f9faff;
}

.login {
  position: relative;
  width: 305px;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 1s;
  transform-style: preserve-3d;
  backface-visibility: hidden;

  &__header {
    > h2 {
      font-size: 24px;
      font-weight: bold;
      color: @g-primary-color;
      text-align: center;
    }

    .logo {
      height: 25px;
      margin-top: 20px;
    }

    .minor-text {
      color: @g-info-color;
    }
  }

  &-body {
    position: relative;

    &__title {
      margin: 36px 0 10px;
      font-size: 20px;
      font-weight: bolder;
      color: @g-font-dark-color;
      text-align: center;
      letter-spacing: 0.69px;
    }

    &__options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;

      .check-account {
        padding: 0;
        color: @g-info-color;
      }

      .remember-me {
        font-size: 14px;
        color: @g-info-color;
        text-align: right;
        letter-spacing: 0.7px;
      }
    }

    &__btn {
      display: block;
      width: 100%;
      padding: 0;
      margin: 30px auto 0;
      font-size: 16px;
      line-height: 48px;
      color: #fff;
      letter-spacing: 1px;
      background: @g-primary-color;
      border: 0;

      &:active {
        background: darken(@g-primary-color, 10%);
      }
    }

    &__choose {
      display: block;
      margin: 16px 0;
      text-align: center;
    }

    /deep/ .el-form-item {
      margin-bottom: 8px;
    }

    /deep/.el-input__inner {
      height: 42px;
      padding: 12px 16px;
      background-color: #f9faff;
      border: 1px solid #eaedff;
      border-radius: 6px;

      // &:input-placeholder,
      &::placeholder {
        color: @g-info-color;
        letter-spacing: 0.7px;
      }
    }

    /deep/.el-form-item__label {
      padding-bottom: 8px;
    }

    /deep/ .el-form-item.is-required .el-form-item__label::before {
      display: none;
    }
  }

  &-select {
    padding: 36px 0 68px;

    &__title {
      margin-bottom: 51px;
      font-size: 20px;
      font-weight: bold;
      line-height: 26px;
      color: #333;
      text-align: center;
      letter-spacing: 1px;
    }

    .character {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-item {
        text-align: center;
        cursor: pointer;

        &__img {
          display: block;
          width: 151px;
          height: 167px;
          margin-bottom: 16px;

          &:hover {
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 10%);
          }
        }

        &__title {
          font-size: 16px;
          color: @g-font-dark-color;
          letter-spacing: 0.8px;
        }
      }
    }
  }
}

.btn__text--apply {
  display: block;
  margin: 12px auto 0;
}

.message-tip {
  margin-bottom: 12px;
  font-size: 12px;
  color: @g-info-color;
  text-align: center;
}
</style>
