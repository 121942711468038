/** 这里是登录相关的埋点 */

export const LOGIN = {
  // 登录按钮
  LOGIN: '113968b1',
  // 申请体验账号按钮
  BTN_APPLY_ACCOUNT: '74bf4ecc',
  // 申请体验账号
  APPLY_ACCOUNT: '79adc21e',
  //学生登录
  STUDENT_LOGIN: '58e216aa',
  //教师端登录
  TEACHER_LOGIN: 'fd89e57a',
};
