/*
 * @Author: jcf
 * @Date: 2021-09-29 14:20:48
 * @Description: go接口nkoapi处理
 * @FilePath: \kkl-online-school\src\common\scripts\utils\goproxy.js
 */
import axios from 'axios';
import { removeMemberId, removeToken, getToken } from './business/login';
import router from '@/router';
import { getStore } from './business/store';
import { tokenExpiredHandler } from './ajax';

const CONFIG = ENV_CONFIG;
const baseURL =
  `${process.env.NODE_ENV === 'development' && process.env.npm_config_mock ? '' : CONFIG.nkoapi}` +
  '/mapi';

// request拦截器参数
const requestInterceptorParams = [
  (config) => {
    let aitoken = getToken();
    if (aitoken) {
      config.headers.aitoken = aitoken;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  },
];

// respone拦截器参数
const responseIntercepterParams = [
  (response) => {
    if (response.config.hint && response.data.error !== 0) {
      KKL.message.error(response.data.msg);
    }
    return response.data;
  },
  (error) => {
    let store = getStore();
    if (error.response) {
      let { status } = error.response;
      status = String(status);
      let query = { ...router.currentRoute.query };
      let redirect = router.currentRoute.path;
      switch (status) {
        // token 失效
        case '401':
          tokenExpiredHandler();
          break;
        // 无权限
        case '403':
          router.replace({
            name: 'noAuth',
          });
          break;
        case '404':
          KKL.message.error('404 控制器不存在');
          break;
        case '449':
          KKL.message.error(error.response.data.msg);
          break;
        case '429':
          KKL.message.error('请求太频繁，请稍后再试');
          break;
      }
    }
    return Promise.reject(error.response);
  },
];

/**
 *
 * 合并参数
 * @param {[object] 配置参数} extraConfig
 * @returns
 */
function mergeConfig(extraConfig) {
  // 默认参数
  let config = {
    needHint: true, // 后台返回非0是否需要报错
    loading: false, // 全局loading
    isPutAllData: false, // 数据返回格式是否是整个对象
  };
  return Object.assign(config, extraConfig);
}

// goproxy为go服务代理的java网关接口服务
// 创建goproxy专用axios实例，因为header不一样
const goproxyRequest = axios.create({
  // baseURL,
  timeout: 20000, // 请求超时时间
  headers: {
    serviceGroup: CONFIG.env,
  },
});
goproxyRequest.interceptors.request.use(...requestInterceptorParams);
goproxyRequest.interceptors.response.use(...responseIntercepterParams);

/**
 *
 * @param {String} service_name
 * @param {Object} params
 * @param {Object} extraConfig
 */
export async function goproxy(service_name, param = {}, extraConfig = {}) {
  let config = mergeConfig(extraConfig);
  const { needHint, isPutAllData } = config;
  if (process.env.NODE_ENV === 'development') {
    config.url = baseURL + '?' + service_name;
  } else {
    config.url = baseURL;
  }
  try {
    let res = await goproxyRequest({
      ...config,
      method: 'post',
      data: {
        service_name,
        param,
      },
    });
    const { data, code } = res;
    if (code === 0 && data.success) {
      if (isPutAllData) {
        return res;
      } else {
        return data;
      }
    } else {
      throw res;
    }
  } catch (err) {
    if (needHint) {
      if (err.code !== 0) {
        err.msg && KKL.message.toast(err.msg);
      } else {
        err.data.message && KKL.message.toast(err.data.message);
      }
    }
    return Promise.reject(err);
  }
}
