export enum LoginType {
  /** 二维码登录 */
  QR_CODE = 0,
  /** 账号密码登录 */
  PASSWORD = 1,
}

export const ScanStatus = {
  /** 初始 */
  init: 0,
  /** 已扫码 */
  scanned: 1,
  /** 登录成功 */
  logined: 2,
  /** 取消登录 */
  canceled: 3,
  /** 已失效 */
  invalid: 4,
} as const;

export type TScanStatus = (typeof ScanStatus)[keyof typeof ScanStatus];
