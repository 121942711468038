export function batchRegister({ buckName, ossKey }) {
  return KKL.Ajax({
    type: 'post',
    noHint: true,
    data: {
      serviceName: 'aischool.SchoolLoginFacade.batchRegister',
      param: {
        buckName,
        ossKey,
      },
    },
  });
}

// 登录
export function login({ password, mobileNo }) {
  return KKL.Ajax({
    type: 'post',
    noHint: true,
    data: {
      serviceName: 'aischool.SchoolLoginFacade.loginV2',
      param: {
        loginInfo: {
          mobileNo,
          password,
          clientType: 'web',
          userAgent: navigator.userAgent,
        },
      },
    },
  });
}

// 通过memberId登录
export function memberIdLogin({ password, memberId }) {
  return KKL.Ajax({
    type: 'post',
    noHint: true,
    data: {
      serviceName: 'aischool.SchoolLoginFacade.memberIdLogin',
      param: {
        bparam: {
          memberId,
          password,
          clientType: 'web',
          userAgent: navigator.userAgent,
        },
      },
    },
  });
}
