<template>
  <div class="tw-text-center">
    <div
      class="tw-relative tw-mx-auto tw-h-[260px] tw-w-[260px]"
      :class="{ '-tw-mt-[10px]': isYucai }"
    >
      <img
        v-if="qrcodeUrl"
        class="tw-h-full tw-w-full"
        :src="qrcodeUrl"
        alt="二维码"
      />
      <div
        v-else
        class="tw-mx-auto tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center"
      >
        <i class="el-icon-loading tw-text-[32px]"></i>
      </div>
      <div
        v-if="scanStatus !== ScanStatus.init || (isGenerated && !qrcodeUrl)"
        class="tw-absolute tw-left-[16px] tw-top-[16px] tw-flex tw-h-[223px] tw-w-[223px] tw-flex-col tw-items-center tw-justify-center tw-bg-[rgba(255,255,255,0.9)]"
      >
        <!-- 登录中 -->
        <span
          v-if="scanStatus === ScanStatus.logined"
          class="tw-inline-flex tw-h-[43px] tw-w-[110px] tw-flex-col tw-items-center tw-justify-center tw-rounded-[26px] tw-bg-white"
        >
          登录中
          <span
            class="tw-relative tw-mt-[3px] tw-inline-block tw-h-[2px] tw-w-[60px] tw-overflow-hidden tw-rounded-[1px] tw-bg-[#ddd]"
          >
            <span
              class="line-loading tw-absolute tw-inline-block tw-h-full tw-w-[32px] tw-bg-primary"
            ></span>
          </span>
        </span>

        <template v-else>
          <span
            class="tw-inline-flex tw-h-[52px] tw-w-[52px] tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-[26px] tw-bg-white"
          >
            <!-- 二维码失效 -->
            <span
              v-if="scanStatus === ScanStatus.invalid || !qrcodeUrl"
              class="tw-inline-flex tw-h-full tw-w-full tw-cursor-pointer tw-items-center tw-justify-center"
              @click="generateQrcode"
            >
              <i
                class="iconfont icon-shuaxin tw-text-[28px] tw-font-bold tw-text-primary"
              ></i>
            </span>

            <!-- 扫码成功 -->
            <i
              v-if="scanStatus === ScanStatus.scanned"
              class="iconfont icon-dagou tw-text-[28px] tw-font-bold tw-text-primary"
            ></i>
          </span>

          <div
            class="tw-mt-[16px] tw-font-bold tw-leading-[1.2] tw-text-kgray-600"
          >
            <template v-if="scanStatus === ScanStatus.invalid"
              >二维码已过期<br />请点击刷新</template
            >
            <template v-if="scanStatus === ScanStatus.scanned"
              >扫码成功<br />请在手机上确认</template
            >
            <template v-if="!qrcodeUrl"
              >二维码生成失败<br />请点击刷新</template
            >
          </div>
        </template>
      </div>
    </div>
    <span class="tw-text-base tw-text-kgray-500">
      打开{{ appText }}App 首页扫一扫登录
    </span>
  </div>
</template>
<script lang="ts">
export default {
  name: 'QrcodeLoginSection',
};
</script>

<script setup lang="ts">
import Qrcode from 'qrcode';
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';

import { SECOND } from '@/common/scripts/utils/time';

import { generateLoginScanCode, loginByScanCode } from '../api';
import { ScanLoginInfo } from '../api/types';
import { ScanStatus, TScanStatus } from '../enums';
import { WLX, YYC } from '@/common/enums/appName';

let timer: number | null = null;
const clearScanStatusList: TScanStatus[] = [
  ScanStatus.canceled,
  ScanStatus.invalid,
  ScanStatus.logined,
];

const props = defineProps({
  isYucai: Boolean,
});

const emits = defineEmits(['loginSuccess']);

const scanCode = ref('');
const qrcodeUrl = ref('');
const scanStatus = ref<TScanStatus>(ScanStatus.init);
const loading = ref(false);
const isGenerated = ref(false);

const appText = computed(() => (props.isYucai ? '育才云朵朵' : '未来校'));
const appName = computed(() => (props.isYucai ? YYC : WLX));

watch(
  () => qrcodeUrl.value,
  (val) => {
    clearTimer();

    val && loopScanStatus();
  },
);

watch(
  () => props.isYucai,
  (val) => {
    clearTimer();
    generateQrcode();
  },
);

onBeforeMount(() => {
  generateQrcode();
});

onBeforeUnmount(() => {
  clearTimer();
});

const generateQrcode = async () => {
  if (loading.value) return;

  scanStatus.value = ScanStatus.init;
  qrcodeUrl.value = '';
  isGenerated.value = false;

  loading.value = true;
  try {
    const resultObject = await generateLoginScanCode(appName.value);

    if (!resultObject) return;

    scanCode.value = resultObject;

    const text = `login://login.${props.isYucai ? 'yc' : 'cs'}?version=1&code=${resultObject}`;
    console.log(text);
    Qrcode.toDataURL(text, {
      errorCorrectionLevel: 'M',
    })
      .then((url: string) => {
        qrcodeUrl.value = url;
      })
      .catch((e) => {
        console.error(e.message);
        // 报警通知
        if (window.skynet) {
          window.skynet.notifyError(e, { source: '登录二维码绘制错误' });
        }
      })
      .finally(() => {
        loading.value = false;
        isGenerated.value = true;
      });
  } catch (e: any) {
    console.error(e.message);
    loading.value = false;
  }
};

const loopScanStatus = () => {
  clearTimer();
  timer = window.setTimeout(() => {
    const code = scanCode.value;
    loginByScanCode(code)
      .then((resultObject) => {
        const { status, loginInfo } = resultObject as ScanLoginInfo;
        // 登录成功，取消登录，登录失效需要清空定时器
        if (clearScanStatusList.includes(status)) {
          clearTimer();
        }

        // 只更新同次的二维码状态
        if (code === scanCode.value) {
          scanStatus.value = status;
        }

        // 登录成功
        if (status === ScanStatus.logined) {
          emits('loginSuccess', loginInfo);
        }
        // 取消登录，需要刷新二维码，开始新一轮的轮训
        else if (status === ScanStatus.canceled) {
          code === scanCode.value && generateQrcode();
        }
      })
      .finally(() => {
        timer && loopScanStatus();
      });
  }, 2 * SECOND);
};

const clearTimer = () => {
  timer && clearTimeout(timer);
  timer = null;
};

defineExpose({
  clearTimer,
});
</script>

<style scoped>
@keyframes loading-animation {
  0% {
    left: 0;
  }

  100% {
    left: 28px;
  }
}

.line-loading {
  animation: loading-animation 0.8s linear infinite alternate;
}
</style>
