import { TAppName, WLX, YYC } from '@/common/enums/appName';
import { ScanCodeLoginCmd, ScanLoginInfo } from './types';
import { IS_YUCAI } from '@/common/enums/hostConfig';
import store from '@/store';

/** web端生成登录二维码 */
export const generateLoginScanCode = (app: TAppName) => {
  return KKL.kapi({
    serviceName: 'aischool.LoginCmdFacade.generateLoginScanCode',
    param: { app },
  });
};

/** web端二维码登录 备注：前端轮询调用该接口 */
export const loginByScanCode = (scanCode: string): Promise<ScanLoginInfo> => {
  return KKL.kapi({
    serviceName: 'aischool.LoginCmdFacade.loginByScanCode',
    param: {
      cmd: {
        scanCode,
        clientType: 'web',
        userAgent: navigator.userAgent,
      },
    },
    noHint: true,
  });
};

/**
 * 根据学校名称搜索学校
 * @returns
 */
export const pageLikeName = ({
  schoolName,
  pageSize = 10,
  pageCurrent = 1,
}: {
  schoolName: string;
  pageSize?: number;
  pageCurrent?: number;
}) => {
  const isYucai = store.state.app.isYucai;
  const qry = { schoolName, pageSize, pageCurrent, app: isYucai ? YYC : WLX };

  return KKL.kapi({
    serviceName: 'aischool.SchoolQueryFacade.pageLikeName',
    param: {
      qry,
    },
  });
};
