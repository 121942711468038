const TOKEN_KEY = 'kaikela_token';
const TOKEN = 'token';
const MEMBER_ID_KEY = 'kaikela_member_id';
let token = null;
let memberId = null;
const defaultDomain = location.hostname.replace(
  /^.+(\.\w+\.[a-z]{2,3})$/,
  '$1',
);

/** 使用弹窗登录的页面path集合 */
export const POPUP_LOGIN_PATH_LIST = [
  '/homeworkManage/homeworkList/createSubject',
];

/** 弹窗登录事件 */
export const POPUP_LOGIN_EVENT_NAME = 'popupLoginEvent';

export function getToken() {
  if (!token) {
    token = localStorage.getItem(TOKEN_KEY) || KKL.cookie.get(TOKEN);
  }
  return token;
}
export function setToken(value) {
  token = value;
  localStorage.setItem(TOKEN_KEY, value);
  KKL.cookie.set(TOKEN, token, { domain: defaultDomain });
}
export function removeToken() {
  token = null;
  localStorage.removeItem(TOKEN_KEY);
  KKL.cookie.remove(TOKEN, { domain: defaultDomain });
}
export function getMemberId() {
  if (!memberId) {
    memberId =
      localStorage.getItem(MEMBER_ID_KEY) || KKL.cookie.get(MEMBER_ID_KEY);
  }
  return memberId;
}
export function setMemberId(value) {
  memberId = value;

  localStorage.setItem(MEMBER_ID_KEY, value);
  KKL.cookie.set(MEMBER_ID_KEY, value, { domain: defaultDomain });
}
export function removeMemberId() {
  memberId = null;
  localStorage.removeItem(MEMBER_ID_KEY);
  KKL.cookie.remove(MEMBER_ID_KEY, { domain: defaultDomain });
}

/**
 * @description: 根据path判断是否使用弹窗登录
 * @param {string} path
 * @return {boolean}
 */
export function shouldUsePopupLogin(path) {
  return POPUP_LOGIN_PATH_LIST.includes(path);
}
