import axios from 'axios';
import qs from 'qs';

import router from '@/router';

import {
  getToken,
  removeMemberId,
  removeToken,
  shouldUsePopupLogin,
  POPUP_LOGIN_EVENT_NAME,
} from './business/login';
import { getStore } from './business/store';
const config = ENV_CONFIG;

//  自定义FetchError类
export class FetchError extends Error {
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  constructor(error) {
    super();
    if (typeof error === 'string') {
      this.message = error;
    } else {
      this.message = error.message;
      this.code = error.code;
    }
  }
}

export function jsonParser(res) {
  let response;
  try {
    response = JSON.parse(res);
  } catch (err) {
    response = res;
    console.log(err);
  }
  return response;
}

export const errorHandler = (res, noHint) => {
  const { code } = res;
  const token = getToken();
  // token失效
  if (code === '1001010' || code === '1002001') {
    tokenExpiredHandler();
    return res;
  }

  const status = res.request?.status;
  let message = res.message || '系统异常';
  if (status && status !== 200) {
    message = '系统繁忙，请稍候再试';
  }
  if (String(res.code) === '3302000') {
    message =
      '您的访问次数超过了题库君的承受能力，建议您明天再来～～我会加强锻炼的～';
  }
  res.message = message;
  res.code = res.code || (res.request && res.request.status);

  if (!noHint) {
    KKL.message.toast(message);
  }

  return res;
};

export const tokenExpiredHandler = () => {
  removeToken();
  removeMemberId();
  // 当前页面弹窗登录
  if (shouldUsePopupLogin(router.currentRoute.path)) {
    window.$_EVENT.emit(POPUP_LOGIN_EVENT_NAME);
  }
  // 重定向到登录页面
  else {
    const store = getStore();
    store.commit('LOGOUT');
    const query = { ...router.currentRoute.query };
    let redirect = router.currentRoute.path;
    if (redirect === '/' || redirect === '/index') {
      redirect = '';
    }

    if (redirect) {
      query.redirect = router.currentRoute.fullPath;
    }
    router.replace({
      path: '/login',
      query,
    });
  }
};

const hasErrTip = false;
/**
 * ajax请求
 * @param {String}      url                  请求地址
 * @param {String}      [type='get']         请求类型 'get' | 'post'
 * @param {Object}      data                 请求参数
 * @param {String}      method               'json' // json传参  'bin' // 二进制传参
 * @param {Boolean}     dontWarn             是否不提示错误信息
 * @param {Boolean}     disableLint          禁用错误校验
 */
export function Ajax({
  url = '',
  method = 'json',
  data = {},
  type = 'get',
  noHint = false,
  mustSend = false,
  extraConfig = {},
}) {
  const ajaxUrl = data.serviceName;

  const methodMap = {
    json: 'application/json',
    bin: 'multipart/form-data',
  };
  const contentType = methodMap[method] || 'application/x-www-form-urlencoded';

  // 处理token等信息
  const headers = {
    'Content-Type': contentType,
  };
  let configUrl = '/api';
  // let configUrl = 'http://kapi.kaike.la/api.do';
  if (
    process.env.NODE_ENV === 'production' &&
    !process.env.npm_config_islocal
  ) {
    // 针对mock数据特殊处理
    if (['prod', 'pre'].includes(config.env)) {
      configUrl = '/api.do';
    }
  }

  const newUrl = `${configUrl}?${ajaxUrl}`;
  const options = {
    url: newUrl,
    method: type,
    headers,
    credentials: 'include',
    withCredentials: true,
    ...extraConfig,
  };
  const store = getStore();
  const token = getToken();
  if (String.prototype.toLowerCase.call(type) === 'get') {
    options.params = data;
  } else {
    const d = {};
    data.param = data.param || {};

    // 通过全局变量 获取学习id
    if (store && store.state && store.state.schoolId) {
      data.param.netSchoolId = store.state.schoolId;
    }
    Object.assign(d, {
      service_name: data.serviceName,
      client_id: '6',
      strategy: 'dubbo',
      timestamp: Date.now(),
      token,
      param: JSON.stringify(data.param),
    });
    options.data = JSON.stringify(d);
  }

  if (mustSend) {
    return onSendBeacon(newUrl, JSON.parse(options.data));
  }

  const $ajax = axios(options);
  return new Promise((resolve, reject) => {
    $ajax
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          const keys = Object.keys(res.data);
          const keyArr = ['resultObject', 'resultList'];
          let index;
          const isExist = keys.some((key, i) => {
            const ind = keyArr.indexOf(key);
            const isHave = ind > -1;
            if (isHave) {
              index = i;
            }
            return isHave;
          });
          if (keys.length <= 2 && isExist) {
            res.data = res.data[keys[index]];
          }
          resolve(res.data);
        } else {
          const error = errorHandler(res, noHint);
          reject(error);
        }
      })
      .catch((error) => {
        console.warn(error);
        const err = errorHandler(error, noHint);
        reject(err);
      });
  });
}

const ajax = Ajax;

Ajax.get = function (url, data) {
  return ajax({
    url,
    data,
    type: 'get',
  });
};

Ajax.post = function (url, data) {
  return ajax({
    url,
    data,
    type: 'post',
  });
};

const onSendBeacon = (url, { serviceName, ...rest }) => {
  const result = navigator.sendBeacon(
    `${url}?${serviceName}`,
    JSON.stringify(rest),
  );

  if (result) {
    return Promise.resolve();
  } else {
    return Promise.reject();
  }
};
