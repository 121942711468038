<!--
 * @Author: linghongxiang linghongxiang@mistong.com
 * @Date: 2024-12-13 17:49:12
 * @LastEditors: linghongxiang linghongxiang@mistong.com
 * @LastEditTime: 2024-12-14 11:31:22
 * @FilePath: \kkl-online-school\src\components\business\LoginDialog\index.vue
 * @Description: 教师端登录弹窗
-->
<template>
  <el-dialog
    class="kkl-dialog"
    width="500px"
    title="登录失效，请重新登录"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    @close="onClose"
  >
    <LoginForm
      class="tw-mx-auto tw-min-h-[480px]"
      :isYucai="IS_YUCAI"
      :reLogin="true"
      :isChangeBackToFront="false"
      @login-success="loginSuccess"
    ></LoginForm>
  </el-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import LoginForm from '@/views/login/components/LoginForm.vue';
import { IS_YUCAI } from '@/common/enums/hostConfig';

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['update:visible', 'loginSuccess']);

const dialogVisible = ref(true);

watch(
  () => props.visible,
  (val) => {
    dialogVisible.value = val;
  },
  {
    immediate: true,
  },
);

const onClose = () => {
  emits('update:visible', false);
};

function loginSuccess(res: object) {
  onClose();
  emits('loginSuccess', res);
}
</script>
